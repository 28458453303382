import "./App.css";
import Maintenance from "./pages/Maintenance";

function App() {
  return (
    <>
      <Maintenance />
    </>
  );
}

export default App;
