import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export type MetalType = "gold" | "silver";
export type PurchaseType = "buy" | "sell";
export type TransactionType = "gram" | "rupees";

type TippyType = {
  title: string;
  value: number;
};

function ParseFloat(str: any, val: any) {
  str = str.toString();
  if (Number.isInteger(Number(str))) {
    return Number(str);
  } else {
    str = str.slice(0, str.indexOf(".") + val + 1);
  }
  return Number(str);
}

// declaring the types for our state
interface InitialState {
  gold: {
    isLoading: boolean;
    title: string;
    price: number;
    increment: boolean;
    percentage: number;
    btn: string;
    sellPrice: number;
    mcxPrice: string | number;
  };
  silver: {
    isLoading: boolean;
    title: string;
    price: number;
    increment: boolean;
    percentage: number;
    btn: string;
    sellPrice: number;
    mcxPrice: string | number;
  };
  metal: MetalType | string;
  purchaseType: PurchaseType | string;
  amount: number;
  transactionType: TransactionType | string;
  displayText: string;
  amountWithoutTax: number;
  gst: number;
  amountWithGst: number;
  tippyText: Array<TippyType>;
}

const initialState: InitialState = {
  gold: {
    isLoading: false,
    title: "Live Gold Price",
    price: 0,
    increment: true,
    percentage: 0.0,
    btn: "View Trends",
    sellPrice: 0,
    mcxPrice: 0,
  },
  silver: {
    isLoading: false,
    title: "Live Silver Price",
    price: 0,
    increment: true,
    percentage: 0.0,
    btn: "View Trends",
    sellPrice: 0,
    mcxPrice: 0,
  },
  metal: "gold",
  purchaseType: "buy",
  amount: 0,
  transactionType: "rupees",
  displayText: "0 gm",
  amountWithoutTax: 0,
  gst: 0,
  amountWithGst: 0,
  tippyText: [],
};

export const manager = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setGoldPriceForShop: (
      state,
      action: PayloadAction<{
        isLoading: false;
        title: string;
        price: number;
        increment: boolean;
        percentage: number;
        btn: string;
        sellPrice: number;
        mcxPrice: string | number;
      }>
    ) => {
      state.gold = { ...state.gold, ...action.payload };
    },
    setSilverPriceForShop: (
      state,
      action: PayloadAction<{
        isLoading: false;
        title: string;
        price: number;
        increment: boolean;
        percentage: number;
        btn: string;
        sellPrice: number;
        mcxPrice: string | number;
      }>
    ) => {
      state.silver = { ...state.silver, ...action.payload };
    },
    setMetalForShop: (state, action: PayloadAction<MetalType | string>) => {
      state.metal = action.payload;
    },
    setPurchaseTypeForShop: (
      state,
      action: PayloadAction<PurchaseType | string>
    ) => {
      state.purchaseType = action.payload;
    },
    setAmountForShop: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
      const amount = action.payload;

      //   if user wants to buy
      if (state.purchaseType === "buy") {
        if (state.metal === "gold") {
          if (state.transactionType === "rupees") {
            let actualAmountForGold = Number(
              ParseFloat(`${(amount / 103) * 100}`, 2)
            ); //194.17
            let calculatedGst = actualAmountForGold * 0;
            // let gst = amount * 0.03;
            const gst = ParseFloat(`${calculatedGst}`, 2);

            state.gst = ParseFloat(`${gst}`, 2);
            state.amountWithoutTax = ParseFloat(`${amount - gst}`, 2);
            state.amountWithGst = amount;
            state.tippyText = [
              {
                title: "Live Gold Price(per gram)",
                value: state.gold.price,
              },
              {
                title: "Value of bought gold",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];
            const goldAu = ParseFloat(`${state.gold.price}`, 4);
            const valueInGramForDisplay = ParseFloat(
              `${actualAmountForGold / state.gold.price}`,
              4
            );

            state.displayText = `${
              isNaN(valueInGramForDisplay) ? 0 : valueInGramForDisplay
            } ${valueInGramForDisplay > 1 ? "gms" : "gm"}`;
          }
          if (state.transactionType === "grams") {
            let gst = ParseFloat(`${amount * state.gold.price * 0.03}`, 2);
            state.gst = gst;
            state.amountWithoutTax = amount * state.gold.price;
            state.amountWithoutTax = ParseFloat(
              `${amount * state.gold.price}`,
              2
            );
            state.amountWithGst = ParseFloat(
              `${amount * state.gold.price + gst}`,
              2
            );

            state.tippyText = [
              {
                title: "Live Gold Price(per gram)",
                value: state.gold.price,
              },
              {
                title: "Value of bought gold",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];

            const valueInRupeesForDisplay = ParseFloat(
              `${amount * state.gold.price + gst}`,
              2
            );
            state.displayText = `${valueInRupeesForDisplay} `;
          }
        }
        if (state.metal === "silver") {
          if (state.transactionType === "rupees") {
            let actualAmountForSilver = Number(
              ParseFloat(`${(amount / 103) * 100}`, 2)
            );
            let calculatedGst = actualAmountForSilver * 0.03;

            const gst = ParseFloat(`${calculatedGst}`, 2);
            state.gst = gst;
            state.amountWithoutTax = amount - gst;
            state.amountWithGst = amount;
            state.tippyText = [
              {
                title: "Live Silver Price(per gram)",
                value: state.silver.price,
              },
              {
                title: "Value of bought silver",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];

            const silverAg = ParseFloat(`${state.silver.price}`, 4);

            const valueInGramForDisplay = ParseFloat(
              `${actualAmountForSilver / state.silver.price}`,
              4
            );

            state.displayText = `${
              isNaN(valueInGramForDisplay) ? 0 : valueInGramForDisplay
            } ${valueInGramForDisplay > 1 ? "gms" : "gm"}`;
          }
          if (state.transactionType === "grams") {
            let gst = ParseFloat(`${amount * state.silver.price * 0.03}`, 2);

            state.gst = gst;
            state.amountWithoutTax = ParseFloat(
              `${amount * state.silver.price}`,
              2
            );
            state.amountWithGst = ParseFloat(
              `${amount * state.silver.price + gst}`,
              2
            );
            state.tippyText = [
              {
                title: "Live silver Price(per gram)",
                value: state.silver.price,
              },
              {
                title: "Value of bought silver",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];

            const valueInRupeesForDisplay = ParseFloat(
              `${amount * state.silver.price + gst}`,
              2
            );
            state.displayText = `${valueInRupeesForDisplay} `;
          }
        }
      }

      // if user wants to sell
      if (state.purchaseType === "sell") {
        if (state.metal === "gold") {
          if (state.transactionType === "rupees") {
            let gst = 0;
            state.gst = gst;
            state.amountWithoutTax = amount - gst;
            state.amountWithGst = amount;
            state.tippyText = [
              {
                title: "Live Gold Price(per gram)",
                value: state.gold.sellPrice,
              },
              {
                title: "Value of sold gold",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];

            const goldAu = ParseFloat(`${state.gold.sellPrice}`, 4);

            const valueInGramForDisplay = Number(amount) / Number(goldAu);

            state.displayText = `${ParseFloat(`${valueInGramForDisplay}`, 4)}
             ${valueInGramForDisplay > 1 ? "gms" : "gm"}`;
          }
          if (state.transactionType === "grams") {
            let gst = 0;
            state.gst = gst;
            state.amountWithoutTax = ParseFloat(
              `${amount * state.gold.sellPrice}`,
              2
            );
            state.amountWithGst = ParseFloat(
              `${amount * state.gold.sellPrice + gst}`,
              2
            );
            state.tippyText = [
              {
                title: "Live Gold Price(per gram)",
                value: state.gold.sellPrice,
              },
              {
                title: "Value of sold gold",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];

            const valueInRupeesForDisplay = ParseFloat(
              `${amount * state.gold.sellPrice + gst}`,
              2
            );
            state.displayText = `${valueInRupeesForDisplay} `;
          }
        }
        if (state.metal === "silver") {
          if (state.transactionType === "rupees") {
            let gst = 0;
            state.gst = gst;
            state.amountWithoutTax = amount - gst;
            state.amountWithGst = amount;
            state.tippyText = [
              {
                title: "Live Silver Price(per gram)",
                value: state.silver.sellPrice,
              },
              {
                title: "Value of bought silver",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];

            const valueInGramForDisplay =
              Number(amount) / Number(state.silver.sellPrice);

            state.displayText = `${ParseFloat(`${valueInGramForDisplay}`, 4)} ${
              valueInGramForDisplay > 1 ? "gms" : "gm"
            }`;
          }
          if (state.transactionType === "grams") {
            let gst = 0;
            state.gst = gst;
            state.amountWithoutTax = ParseFloat(
              `${amount * state.silver.sellPrice}`,
              2
            );
            let x = amount * state.silver.sellPrice + gst;
            state.amountWithGst = ParseFloat(`${x}`, 2);
            state.tippyText = [
              {
                title: "Live silver Price(per gram)",
                value: state.silver.sellPrice,
              },
              {
                title: "Value of sold silver",
                value: state.amountWithoutTax,
              },
              {
                title: "Gst(3%)",
                value: state.gst,
              },
              {
                title: "Total Amount",
                value: state.amountWithGst,
              },
            ];
            let y = amount * state.silver.sellPrice + gst;
            const valueInRupeesForDisplay = ParseFloat(`${y}`, 2);
            state.displayText = `${valueInRupeesForDisplay} `;
          }
        }
      }
    },
    setTransactionTypeForShop: (
      state,
      action: PayloadAction<TransactionType | string>
    ) => {
      state.transactionType = action.payload;
    },
    setDisplayText: (state, action: PayloadAction<string>) => {
      state.displayText = action.payload;
    },
    setAmountWithoutTax: (state, action: PayloadAction<number>) => {
      state.amountWithoutTax = action.payload;
    },
    setGst: (state, action: PayloadAction<number>) => {
      state.gst = action.payload;
    },
    setAmountWithGst: (state, action: PayloadAction<number>) => {
      state.amountWithGst = action.payload;
    },
  },
});

// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setMetalForShop,
  setGoldPriceForShop,
  setSilverPriceForShop,
  setAmountForShop,
  setPurchaseTypeForShop,
  setTransactionTypeForShop,
} = manager.actions;

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const getMetalTypeShop = (state: RootState) => state.shop.metal;
export const getAmount = (state: RootState) => state.shop.amount;
export const getTippy = (state: RootState) => state.shop.tippyText;
export const getDisplayText = (state: RootState) => state.shop.displayText;
export const getAmountWithouTax = (state: RootState) =>
  state.shop.amountWithoutTax;
export const getGstForShop = (state: RootState) => state.shop.gst;
export const getTotalAmountForshop = (state: RootState) =>
  state.shop.amountWithGst;
export const getTransactionTypeForShop = (state: RootState) =>
  state.shop.transactionType;

// exporting the reducer here, as we need to add this to the store
export default manager.reducer;
