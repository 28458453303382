import backgroun from "../pages/backgroun.jpg";
interface IProps {
  setStep: (stepNumber: number) => void;
  step: number;
}
const Maintenance = () => {
  const handleclickPlayStore = () => {
    window.open("https://sig1e.app.link/BDG-IND");
  };
  return (
    <>
      {/* <div className="flex items-center h-screen justify-center">
                <div>
            <img  src="https://imagesbdg.sgp1.digitaloceanspaces.com/12c4764e-2b07-4a14-8b45-6398aa7f5dec" className="w-full" />
                <h1 className="text-center font-bold text-xl mb-1"> This App is under maintenance</h1>
                <h2 className="text-center">We are preparing to serve you better </h2>
                </div>
                </div> */}
      <div className="bg-white">
        <div className="bg-white">
          <img
            onClick={handleclickPlayStore}
            src={backgroun}
            alt="Background image"
            className="max-w-full max-h-full"
          />
        </div>
      </div>
    </>
  );
};

export default Maintenance;
