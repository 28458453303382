import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { v4 as uuidv4, v4 } from "uuid";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";

localStorage.setItem("JourneyId", uuidv4());
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}></Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
